import Storage from '@octadesk-tech/storage'

import { homeSteps } from '@/modules/Home/utils/home-steps.util'

import { PRODUCT_TARGETS } from '@/common/helpers/enums/product-targets.enum'
import { RoleTypes } from '@/common/helpers/enums/role-types'

import { AuthState } from '@/store/interfaces'
export const tenants = (state: AuthState) => state.tenants

export const isAuth = (state: AuthState): boolean => !!state.access_token

export const octaAuthenticated = (state: AuthState) =>
  state && state.octaAuthenticated

export const tenantId = (state: AuthState) =>
  state && state.octaAuthenticated?.tenantId

export const access_token = (state: AuthState) => state.access_token

export const redirectAfterLogin = (state: AuthState) => state.redirectAfterLogin

export const idOctadesk = (state: AuthState) => state.idOctadesk

export const subDomain = (state: AuthState) =>
  state?.octaAuthenticated?.subDomain

export const userLogged = (state: AuthState) => state?.userlogged

export const userToken = (state: AuthState) => state?.jwtoken

export const subDomainStatus = (state: AuthState) => state?.subDomainStatus

export const subDomainIsTrial = ({ subDomainStatus }: AuthState) => {
  const domain: any = subDomainStatus || Storage.getItem('status')
  return domain && domain.isTrial
}

export const companyModel = (state: AuthState) => state?.companyModel

export const companyCreationDate = (state: AuthState) =>
  state?.companyModel?.company?.dateCreation

export const apis = (state: AuthState) => state?.apis

export const connectionStatus = (state: AuthState) => state?.connectionStatus

export const isTrial = (state: AuthState) => {
  return state.subDomainStatus?.isTrial || state.subDomainStatus?.isTrialExpired
}

export const isTrialOver = (state: AuthState) =>
  state?.subDomainStatus?.isTrialExpired || false

export const hasBlocked = (state: AuthState) =>
  state?.subDomainStatus?.isBlocked || false

export const hasPaymentIssue = (state: AuthState) =>
  state?.subDomainStatus?.paymentIssue || false

export const isAdmin = (state: AuthState) => state?.octaAuthenticated?.admin

export const trialOverModal = (state: AuthState) =>
  state?.trialOverModal || false

export const pendingPaymentModal = (state: AuthState) =>
  state?.pendingPaymentModal || false

export const loginInfo = (state: AuthState) => state?.loginInfo

export const loginMethod = (state: AuthState) => state?.loginInfo?.type

export const isAuthorized = (state: AuthState) => (permissionLevel: number) => {
  return state.userlogged?.roleType ?? 0 <= permissionLevel
}

export const trayWidget = (state: AuthState) => {
  const subDomainStatus = state.subDomainStatus
  return (
    subDomainStatus &&
    subDomainStatus.whiteLabel &&
    subDomainStatus.whiteLabel.widget
  )
}

export const isWhitelabel = (_: any, state: any) =>
  state.octaAuthenticated?.whiteLabel

export const isTrayWhitelabel = (_: any, state: any) =>
  state.octaAuthenticated?.whiteLabel &&
  state.octaAuthenticated?.whiteLabelCode === 'tray'

export const isMoskit = (_: any, state: any) =>
  state.octaAuthenticated?.whiteLabelCode === 'moskit'

export const targetBasedOnProduct = () => PRODUCT_TARGETS.NEW_PRODUCT

export const notMigratedExperimentingNewProduct = (
  _state: AuthState,
  getters: any
) => {
  return (
    (getters.hasNewChatEnabled || !getters.octaAuthenticated?.environmentId) &&
    !getters.isNewBilling &&
    !getters.isTrayWhitelabel
  )
}

export const currentAgentAppsIds = (state: AuthState) =>
  state.currentAgentAppsIds

export const tenantConfigs = (state: AuthState) => state.tenantConfigs

export const getTenantConfigByValue =
  (state: AuthState, getters: any) => (value: string) => {
    const config: any = getters.tenantConfigs.find(
      (configItem: { code: string }) => configItem.code === value
    )
    return config ? config : null
  }
export const checkCompanySize =
  (state: AuthState, getters: any) => (sizeToCheck: string) => {
    const config: any = getters.tenantConfigs.find(
      (configItem: { code: string }) => configItem.code === 'companyEmployees'
    )
    return config ? sizeToCheck === config.value : false
  }

export const painInfo = (state: AuthState, getters: any) => {
  const onboardingPain = getters.getTenantConfigByValue('onboardingPain')
  return onboardingPain ? homeSteps[onboardingPain.value] : null
}
export const showScheduleButton = (state: AuthState, getters: any) => {
  return !getters.isTrayWhitelabel && !getters.checkCompanySize('1 colaborador')
}

export const isMigratedEnvironment = (state: AuthState) => {
  return state.migratedEnvironment
}

export const hasToChangePassword = (state: AuthState) => {
  return state.passwordExpired || Storage.getItem('passwordExpired') === 'true'
}

export const isExternalUser = (state: AuthState) => {
  return state.userlogged?.roleType === RoleTypes.ExternalPerson
}

export const ssoTenants = (state: AuthState) => state.ssoTenants
