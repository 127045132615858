<template>
  <div
    :class="[
      'flex flex-col justify-center items-center text-center overflow-hidden',
      {
        [widthValue]: true,
        [heightValue]: true,
        'fixed inset-0 z-50 bg-white !h-screen !w-screen': fullscreenValue
      }
    ]"
  >
    <div class="w-full h-full overflow-hidden max-w-72 max-h-72">
      <dotlottie-wc
        src="/images/loading-bubbles.json"
        class="w-full h-full"
        autoplay
        loop
      />
    </div>

    <octa-title
      v-if="!hideMessageValue"
      tag="h5"
      :class="$style['message']"
      v-tooltip="messageToDisplay"
    >
      <octa-text-overflow>
        {{ messageToDisplay }}
      </octa-text-overflow>
    </octa-title>
  </div>
</template>

<script setup lang="ts">
  import { defineProps, ref, computed, onMounted, onUnmounted } from 'vue'
  import { useI18n } from 'vue-i18n-composable'

  import { OctaTitle, OctaTextOverflow } from '@octadesk-tech/web-components'

  const {
    message,
    fullscreen = false,
    w = 'w-full',
    h = 'h-full',
    hideMessage = false
  } = defineProps<{
    message?: string
    fullscreen?: boolean
    w?: string
    h?: string
    hideMessage?: boolean
  }>()

  const { t } = useI18n()

  const messageInterval = ref<any>(undefined)

  const parsedMessage = ref<string>('')

  const intervalMessagesTime = 4000

  function handleSetAlleatoryMessage(value = '') {
    const messages: Array<string> = t('loadingMessages.customMessages') as any

    if (value) {
      messages.push(value)
    }

    messageInterval.value = setInterval(() => {
      parsedMessage.value =
        messages[Math.floor(Math.random() * messages.length)]
    }, intervalMessagesTime)
  }

  function handleMessage() {
    if (!message) {
      handleSetAlleatoryMessage()
    } else {
      parsedMessage.value = message

      setTimeout(() => {
        handleSetAlleatoryMessage(message)
      }, intervalMessagesTime)
    }
  }

  const widthValue = computed(() => w)

  const heightValue = computed(() => h)

  const fullscreenValue = computed(() => fullscreen)

  const hideMessageValue = computed(() => hideMessage)

  const messageToDisplay = computed(() => {
    const genericMessages: Array<string> = t(
      'loadingMessages.genericMessages'
    ) as any

    return !parsedMessage.value
      ? genericMessages[Math.floor(Math.random() * genericMessages.length)]
      : parsedMessage.value
  })

  onMounted(() => {
    handleMessage()
  })

  onUnmounted(() => {
    clearInterval(messageInterval.value)
  })
</script>

<style lang="scss" module>
  .message {
    @apply w-full font-semibold leading-normal text-center;
    font-size: 86%;
  }
</style>
